import React from "react"

import { SiLeetcode } from "react-icons/si"
import { SiHackerrank } from "react-icons/si"
import { FaInstagram } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"
import { FaGithub } from "react-icons/fa"

export const links = [
  {
    id: 1,
    text: "home",
  },
  {
    id: 2,
    text: "portfolio",
  },
  {
    id: 3,
    text: "about",
  },
  {
    id: 4,
    text: "contact",
  },
]

export const contactInfo = [
  {
    id: "1",
    title: "email",
    info: "jcjenson3@gmail.com",
  },
  {
    id: "2",
    title: "phone",
    info: "801 833 3346",
  },
  {
    id: "3",
    title: "location",
    info: "Mission Viejo, CA",
  },
]

export const socialIcons = [
  { id: "2", url: "#", icon: <FaGithub /> },
  { id: "3", url: "#", icon: <FaLinkedinIn /> },
  { id: "1", url: "#", icon: <FaInstagram /> },
  { id: "4", url: "#", icon: <SiLeetcode /> },
  { id: "5", url: "#", icon: <SiHackerrank /> },
]
