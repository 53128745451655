import React from "react"
import styled from "styled-components"
// import { socialIcons } from "./utils"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Container className="section">
      <div className="footer-center">
        <div className="footer-text">
          <p>
            Copyright &copy; {year} All rights reserved | This website was made
            by{" "}
            <a href="https://www.joeyjenson.com/" className="website-link">
              Joey Jenson
            </a>
          </p>
        </div>
        {/* <ul className="footer-social">
          {socialIcons.map(({ id, url, icon }) => {
            return (
              <li key={id} className="social">
                <a href={url} className="social-link">
                  {icon}
                </a>
              </li>
            )
          })}
        </ul> */}
      </div>
    </Container>
  )
}

const Container = styled.footer`
  .footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-text {
    color: #8c8c8c;
    text-align: center;
    /* margin-bottom: 4rem; */
  }
  .website-link {
    transition: 0.25s;
    color: #108084;
    &:hover {
      transition: 0.25s;
      color: #45c5ca;
    }
  }
  .footer-social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .social {
    background: #333;
    margin: 0 0.55rem 0.5rem;
    padding: 1rem 1rem 0.5rem;
    border-radius: 50%;
  }
  .social-link {
    svg {
      font-size: 1.5rem;
      color: #ffffff;
    }
  }

  @media (min-width: 800px) {
    .social {
      margin: 0 1rem;
    }
  }
`

export default Footer
