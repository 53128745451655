import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import "../assets/css/index.css"

const Error = () => {
  return (
    <Layout>
      <Container>
        <section className="section">
          <h1>404</h1>
          <h3>page not found</h3>
          <div className="container">
            <Link className="btn home-btn" to="/">
              back to homepage
            </Link>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

const Container = styled.main`
  min-height: 75vh;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  h1 {
    font-size: 10rem;
    margin-top: 2.5rem;
  }
  .container {
    margin-top: 5rem;
  }
  .btn-container {
    width: 30%;
  }
  .home-btn {
    width: 100%;
    color: #181818;
    background-color: #17b8bd;
    transition: 0.125s;

    &:hover {
      transition: 0.125s;
      background-color: #e5f4f4;
    }
  }
`

export default Error
