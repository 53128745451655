import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { links } from "./utils"

const NavLinks = ({ isOpen, closeNavbar }) => {
  return (
    <Container className={`nav-links ${isOpen ? "show" : "collapse"}`}>
      {links.map(link => {
        const { id, text } = link
        return (
          <li key={id}>
            <Link
              to={`${text !== "home" ? "/" + text : "/"}`}
              className="link"
              activeClassName="active"
              onClick={closeNavbar}
            >
              {text}
            </Link>
          </li>
        )
      })}
    </Container>
  )
}

const Container = styled.ul`
  background-color: #181818;
  
    li {
      padding: 1rem 1.25rem;
    }
    .link {
      width: 100%;
      /* color: #000; */
      font-weight: 200;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      transition: .125s;
      &:hover {
        transition:.25s;
        color: #e5f4f4;
      }
    }
    .link-scrolled {
      color: #000;
    }

    @media (min-width: 800px) {
      background-color: transparent;

      .link {
        color: #53565a;
      }
      .link-scrolled {
        color: #000;
      }
    }
  }
`

export default NavLinks
