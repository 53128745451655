import React, { useState } from "react"
import styled from "styled-components"
import NavLinks from "./NavLinks"
import { FaBars, FaTimes } from "react-icons/fa"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const closeNavbar = () => {
    setIsOpen(false)
  }
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Nav className="nav ">
      <div className="nav-center">
        <button
          type="button"
          className="nav-toggle links-btn"
          onClick={handleToggle}
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <NavLinks isOpen={isOpen} closeNavbar={closeNavbar} />
      </div>
    </Nav>
  )
}

const Nav = styled.nav`
  width: 100%;
  display: flex;
  background-color: transparent;
  position: absolute;
  z-index: 10;

  .nav-center {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .nav-toggle {
    background: transparent;
    border: none;
    padding: 0.25rem 0.75rem;
    svg {
      font-size: 2rem;
      font-weight: 200;
      color: #53565a;
    }
  }

  @media (min-width: 800px) {
    height: 4rem;
    align-items: center;
    position: absolute;
    background-color: transparent;

    .nav-center {
      flex-direction: row;
      justify-content: center;
    }
    .nav-toggle {
      display: none;
    }
  }
  }
`

export default Navbar
